import "swiper/css";
import "swiper/css/navigation";
import { HeadLine } from "../component";
import "tippy.js/dist/tippy.css";
import FundingCarousel from "../carousel/FundingCarousel";

const Funding = ({ classes = "pt-2 pb-5", bgWhite, fundingProperties }) => {
  return (
    <>
      <section className={classes}>
        <div className="container">
          <HeadLine
            text="Funding Properties"
            classes="font-display text-jacarta-500 mt-4 text-xl dark:text-white text-center"
          />

          <div className="relative items-center">
            <FundingCarousel fundingProperties={fundingProperties} />
          </div>
        </div>
        {/* <!-- end hot bids --> */}
      </section>
    </>
  );
};

export default Funding;
