import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/image";
import "tippy.js/dist/tippy.css";

import Link from "next/link";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

import { useSelector } from "react-redux";
import Items_Countdown_timer from "../items_countdown_timer";
import { items_data } from "../../data/items_data";
import formatter from "../formatter";
import { backendUrl } from "../../redux/backendConfig";
import { dateToTimestamp } from "../../utils/dateToTimestamp";
import { useEffect, useState } from "react";
import { getFundedAmountProperty } from "../metamask/Metamask";

const FundingCarousel = ({ fundingProperties }) => {
  const [fundedArray, setFundedArray] = useState([]);

  useEffect(() => {
    const fetchFundedAmounts = async () => {
      try {
        const tempValuesArray = await Promise.all(
          fundingProperties.map((item) =>
            getFundedAmountProperty(item.contract)
          )
        );
        setFundedArray(tempValuesArray);
      } catch (error) {
        console.error("Error fetching funded amounts:", error);
      }
    };

    fetchFundedAmounts();
  }, [fundingProperties]);

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar]}
        spaceBetween={30}
        slidesPerView="auto"
        loop={false}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          565: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
          1100: {
            slidesPerView: 4,
          },
        }}
        navigation={{
          nextEl: ".funding-swiper-button-next",
          prevEl: ".funding-swiper-button-prev",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {fundingProperties.map((item, index) => {
          return (
            <SwiperSlide key={item.id} className="text-white">
              <article className="relative">
                <div
                  style={{ transform: "scale(0.65)" }}
                  className="absolute bottom-0 left-0 mr-2 mb-2"
                >
                  <Items_Countdown_timer
                    time={
                      (dateToTimestamp(item.launch_at) +
                        item.days_for_funding * 3600 * 24) *
                        1000 -
                      Date.now()
                    }
                  />
                </div>
                <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg text-jacarta-500">
                  <figure>
                    {/* {`item/${itemLink}`} */}
                    <Link href={`/item/${item.id}`}>
                      <a>
                        <div className="w-full">
                          {console.log(`${backendUrl}${item.main_image}`)}
                          <img
                            src={`${backendUrl}${item.main_image}`}
                            alt={item.title}
                            height={230}
                            width={230}
                            layout="responsive"
                            className="rounded-[0.625rem] w-full"
                            loading="lazy"
                          />
                        </div>
                      </a>
                    </Link>
                  </figure>
                  <div className="mt-4 flex items-center justify-between">
                    <Link href={`/item/${item.id}`}>
                      <a>
                        <div className="font-display text-jacarta-600 dark:hover:text-accent hover:text-accent text-base dark:text-white">
                          {item.title}
                        </div>
                      </a>
                    </Link>
                    <div className="text-accent font-display">
                      Id&nbsp;#{item.id}
                    </div>
                  </div>
                  <div className="mt-2 text-sm flex">
                    <h6 className="dark:text-jacarta-300 text-jacarta-500">
                      Property Price:
                    </h6>
                    <div className="ml-2">
                      <Image
                        src="/images/tether-usdt-logo.svg"
                        width={12}
                        height={12}
                        alt="usdt_logo"
                      ></Image>
                      <span className="ml-1 text-green font-bold">
                        {formatter.format(item.price)} DAI
                      </span>
                    </div>
                  </div>

                  <div className="mt-2 text-sm">
                    <span className="dark:text-jacarta-300 text-jacarta-500">
                      Funded:
                    </span>
                    <span className="dark:text-jacarta-100 text-jacarta-700 ml-2">
                      {formatter.format(
                        parseInt(fundedArray[index]) / 10 ** 18
                      )}{" "}
                      / {formatter.format(item.price)}
                    </span>
                  </div>

                  <div className="mt-5 flex  justify-end">
                    <Link href={`/item/${item.id}`}>
                      <div className=" text-accent-light hover:text-accent-dark font-display text-sm font-semibold cursor-pointer">
                        Fund
                      </div>
                    </Link>
                  </div>
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <!-- Slider Navigation --> */}
      <div className="group funding-swiper-button-prev swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group funding-swiper-button-next swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default FundingCarousel;
