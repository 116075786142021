import Link from "next/link";
import { useTheme } from "next-themes";
import { useMetaMask } from "metamask-react";
import Image from "next/image";
import { addressDai } from "../../data/contractConstants";
import Tippy from "@tippyjs/react";
import { MdOutlineContentCopy } from "react-icons/md";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Hero = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const { status, connect, account, chainId, ethereum } = useMetaMask();

  return (
    <section className="relative pb-10 pt-20 md:pt-32 h-1527">
      <div className="container h-full mx-auto">
        <div className="grid h-full items-center gap-4 md:grid-cols-12 pl-6">
          <div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-20 xl:col-span-4">
            <h5 className="flex items-end ml-auto mr-4 mb-1 font-display">
              <span className="mr-1">Deployed On:</span>

              <span className="ml-1 text-accent">Sepolia</span>
              <div
                className={`-mb-1 ml-1 ${isDarkMode ? "brightness-150" : ""}`}
              >
                <Image
                  src={"/images/eth-icon.svg"}
                  height={20}
                  width={20}
                  alt="eth_logo"
                />
              </div>
            </h5>

            <h1 className="text-jacarta-700 font-bold font-display mb-1 text-center text-5xl dark:text-white md:text-left lg:text-6xl">
              Buy Properties using{" "}
              <span className="animate-gradient ml-1">Crypto</span>, Earn
              Returns!
            </h1>

            <p className="dark:text-jacarta-200 mb-8 text-center text-lg md:text-left">
              Discover a new way of real estate investment where you can divide
              your investments into multiple properties thanks to blockchain
              technology!!.
            </p>
            <div className=" font-display mb-4">
              <div className="">
                For testing, this website uses this Aave DAI Address:
              </div>
              <div className="flex">
                <Link
                  href="https://sepolia.etherscan.io/address/0xe5118E47e061ab15Ca972D045b35193F673bcc36"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="hover:text-accent cursor-pointer">
                    {addressDai}
                  </span>
                </Link>
                <div className="ml-2 mt-1">
                  <Tippy
                    hideOnClick={false}
                    content={<span className="text-sm -mt-2 p-2">copy</span>}
                    placement="right"
                  >
                    <button className="js-copy-clipboard dark:text-jacarta-200 max-w-[10rem] select-none overflow-hidden text-ellipsis whitespace-nowrap">
                      <CopyToClipboard text={addressDai}>
                        <span className="hover:text-accent">
                          <MdOutlineContentCopy />
                        </span>
                      </CopyToClipboard>
                    </button>
                  </Tippy>
                </div>
              </div>
              <p className="mt-2">You can accuire DAI in AAve Faucet:</p>

              <Link
                href={"https://gho.aave.com/faucet/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="cursor-pointer hover:underline hover:text-accent">
                  https://gho.aave.com/faucet/
                </div>
              </Link>
            </div>
            <div className="flex space-x-4">
              <Link href="/help_center/get_started">
                <a className="bg-accent shadow-accent-volume hover:bg-accent-dark w-36 rounded-lg py-3 px-8 text-center font-semibold text-white transition-all">
                  Learn&nbsp;More
                </a>
              </Link>
              {status === "notConnected" && (
                <button
                  onClick={connect}
                  className="text-accent hover:text-white shadow-white-volume w-36 rounded-lg bg-jacarta-100 py-3 px-8 text-center font-semibold transition-all hover:bg-accent-dark hover:shadow-accent-volume"
                >
                  <div className="flex">
                    <div className="mr-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={24}
                        height={24}
                        className="fill-current"
                        style={{ transition: "fill 0.3s" }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
                      </svg>
                    </div>
                    <div className="">Connect</div>
                  </div>
                  <style jsx>{`
                    button:hover svg.fill-current {
                      fill: white;
                    }
                  `}</style>
                </button>
              )}
            </div>
          </div>

          {/* <!-- Hero image --> */}

          <div className="col-span-6 xl:col-span-8">
            <div className="relative text-center md:pl-8 md:text-right">
              <img
                src="/images/puzzleHouse.png"
                alt=""
                className="inline-block w-72 sm:w-full lg:w-[24rem] xl:w-[35rem]  mr-16 mb-16"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
